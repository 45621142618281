/* eslint-disable-next-line */
__webpack_public_path__ = '/static/js/maxarea/';
import Slider from '../common/slider';
class MaxArea extends lm.Composant {
  /**
   * @constructor
   * @param {String} id
   */
  constructor(id) {
    super(id);
    [...document.querySelectorAll('#component-maxarea .js-slider')].forEach(element => {
      new Slider({
        element,
        manualScroll: 'touch',
        fakeInfinite: true
      });
    });
    const homepageSliderIo = document.querySelector("#arm-homepage-slider-io");
    document.querySelector('#arm-homepage-slider-open-modal')?.addEventListener('click', () => {
      if (homepageSliderIo) {
        document.dispatchEvent(new CustomEvent("arm-popin-dsa-open", {
          detail: homepageSliderIo.innerHTML
        }));
      }
    });
  }
}
lm.DOMReady(() => {
  new MaxArea('component-maxarea');
});